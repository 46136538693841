import React from 'react';
import { Button } from '../components/button/button';
import {Layout} from '../components/layout/layout';
import { URLS } from '../components/nav/nav';

const NoPageFound = () => (
  <div style={{display: 'flex', flexDirection: 'column', minHeight: '100vh'}}>
    <Layout>
      <div  style={{display: 'flex', alignItems: 'center', flexDirection:'column', justifyContent: 'center', margin: 'auto'}}>

      <h1 style={{marginBottom:60}}>
        There is no such page.
      </h1>
      <h2>
        Find more information about Our Community and Blog.
      </h2>
      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto', gap:'20px'}}>
        <Button to={URLS.COMMUNITY}>Our Community</Button>
        <Button to={URLS.BLOG}>Blog</Button>
      </div>
      </div>
    </Layout>
  </div>
);

export default NoPageFound;
